<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :queryInfo.sync="queryInfo"
        :tool-list="['grade',  'college', 'major', 'stuStatus','keyword']"
        :insert-select-all="[ 'college', 'major']"
        @on-search="renderTable(1)">
        <el-form-item label="学制" label-width="60px">
          <el-select v-model="queryInfo.lengthOfSchooling" clearable size="small">
            <el-option
              v-for="{paramName,paramValue} in $store.state.systemParam.lengthOfSchoolingType"
              :key="paramValue"
              :label="paramName"
              :value="Number(paramValue)" />
          </el-select>
        </el-form-item>
      </view-search-form>
      <div class="header-button">
        <el-button type="success" size="small" @click="exportStudentInfo">导出该筛选条件学生</el-button>
        <el-button type="success" plain size="small" piain @click="visibleExportDialog=true">批量导出(带照片)
        </el-button>
        <upload-excel :permit-key="['stu:import:edit']"
                      :loading.sync="loading"
                      :api="$http.importExcelHttp.importStudent"
                      @on-download="$http.exportExcelHttp.exportStudentTemplate()"
                      @on-success="renderTable(1)" />
        <el-button v-permission="['stu:import:edit']"
                   type="danger"
                   size="small"
                   :disabled="!stuInfo.length"
                   @click="changeIntoNotReport"
                   v-if="canBatchSaveNoPay"
        >转入未缴费名单
        </el-button>
        <el-button v-permission="['stu:import:edit']" type="primary" size="small" @click="showDialog()"
        >添加学生
        </el-button>
      </div>
    </template>
    <!--  body  -->
    <el-table
      :data="tableData"
      style="width: 100%"
      border
      stripe @selection-change="selectStudent">
      <el-table-column type="selection" width="55" v-if="canBatchSaveNoPay" />
      <el-table-column prop="examineNo" label="录取编号" width="120" />
      <el-table-column prop="stuNo" label="学号" width="120" align="center">
        <template v-slot="{row}">
          {{ row.stuNo || '--' }}
        </template>
      </el-table-column>
      <el-table-column prop="grade" label="年级" width="80" />
      <el-table-column label="姓名" width="120">
        <template v-slot="{row}">
          <!-- showSubDialog的title属性没有上,借用来传递专业信息  -->
          <el-button type="text" @click="showSubDialog('visibleOnlyReadDialog','onlyReadDialogRef',row.id,row)">
            {{ row.stuName }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="学籍状态" width="120">
        <template v-slot="{row}">
          {{ $http.getParamName(row.stuStatus, $store.state.systemParam.stuStatus) }}
        </template>
      </el-table-column>
      <el-table-column prop="sexType" label="性别" width="80">
        <template v-slot="scoped">
          <span v-if="Number(scoped.row.sexType) === 1">男</span>
          <span v-else>女</span>
        </template>
      </el-table-column>
      <el-table-column prop="collegeName" label="院系" width="150" />
      <el-table-column prop="majorName" label="专业" width="150" />
      <el-table-column prop="className" label="班级" width="170">
        <template v-slot="{row}">
          {{ row.className || '未绑定' }}
        </template>
      </el-table-column>
      <el-table-column prop="campusName" label="校区名称" width="100" />
      <el-table-column prop="apartmentName" label="宿舍" width="170">
        <template v-slot="{row}">
            <span v-if="row.dormitoryName && row.dormitoryBedNo"
            >{{ row.dormitoryName }}{{ row.roomName }}室{{
                row.dormitoryBedNo
              }}号床</span>
          <span v-else>未分配</span>
        </template>
      </el-table-column>
      <el-table-column label="门禁照片" width="60" align="center">
        <template v-slot="{row}">
          <template v-if="row.headshot">
            <el-popover
              placement="top"
              width="189"
              :title="`门禁照片：${row.stuName}`"
              lazy
              trigger="hover">
              <el-image style="width:179px; height:auto;" :preview-src-list="[$fileUrl + row.headshot]"
                        :src="$fileUrl + row.headshot" fit="contain" />
              <el-button slot="reference" type="text" size="mini">
                <i class="el-icon-more" />
              </el-button>
            </el-popover>
          </template>
          <span v-else class="font-grey">--无--</span>
        </template>
      </el-table-column>
      <el-table-column label="免缴费登录" width="130" align="center">
        <template v-slot="{row}">
          <el-switch
            v-permission="['stu:import:edit']"
            v-model="row.payPassFlag"
            active-color="var(--color-success)"
            inactive-color="#d0d6e8"
            :active-value="1"
            active-text="是"
            :inactive-value="0"
            inactive-text="否"
            @change="handleSwitch(row)" />
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column label="操作" width="150" fixed="right">
        <template v-slot="{row}">
          <el-button
            v-permission="['stu:import:edit']"
            type="primary"
            size="mini"
            @click="showDialog(row.id,`-${row.stuName}`)"
          >编辑
          </el-button>
          <el-button
            v-permission="['stu:import:del']"
            size="mini"
            type="danger"
            @click="del(deleteStudent, row.id,`学生-${row.stuName}`)"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!--  编辑学生信息 dialog  -->
    <student-dialog ref="dialogRef" :visible.sync="visibleDialog" v-if="visibleDialog" @on-close="renderTable" />
    <!--  查看学生信息 dialog  -->
    <student-only-read-dialog ref="onlyReadDialogRef" :visible.sync="visibleOnlyReadDialog"
                              v-if="visibleOnlyReadDialog" />
    <!--  导出带照片的学生excel dialog  -->
    <export-student-dialog ref="exportStudentDialogRef" :visible.sync="visibleExportDialog"
                           v-if="visibleExportDialog" />
  </table-view>
</template>

<script>
import { getStudentList, deleteStudent, addStudent } from '@/api/student'
import tableView from '@/vue/mixins/table-view'
import studentDialog from '@/views/pages/recruitManage/import-student/component/studentDialog.vue'
import StudentOnlyReadDialog from '@/views/pages/recruitManage/import-student/component/studentOnlyReadDialog.vue'
import ExportStudentDialog from '@/views/pages/recruitManage/import-student/component/exportStudentDialog.vue'

export default {
  name: 'importStudent',
  components: {
    ExportStudentDialog,
    StudentOnlyReadDialog,
    studentDialog
  },
  mixins: [tableView],
  data() {
    return {
      visibleOnlyReadDialog: false,
      visibleExportDialog: false,
      queryInfo: {
        lengthOfSchooling: null, // 学制
        stuStatus: null, // （0——未注册，1——在读，2——毕业）
        campusId: null,
        grade: new Date().getFullYear(),
        collegeId: null,
        majorId: null
      },
      canBatchSaveNoPay: false,
      stuInfo: []
    }
  },
  created() {
  },
  methods: {
    deleteStudent, // 删除api
    // 转入未报到名单
    changeIntoNotReport() {
      this.$confirm('转到未报到名单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http.updateStudentInfo({ stuInfoList: this.stuInfo }).then(() => {
            this.renderTable()
            this.$message.success('转入成功')
          }).catch()
        })
        .catch(() => {
          this.$message('已取消')
        })
    },
    // 选择学生
    selectStudent(stuIds) {
      this.stuInfo = stuIds.map((item) => {
        return {
          id: item.id,
          userId: item.userId,
          stuStatus: 6
        }
      })
    },
    // 获取学生列表
    async renderTable(pageNum) {
      await this.getTableData(getStudentList, pageNum)
    },
    // 导出已缴费名单
    exportStudentInfo() {
      let stuStatusName = ''
      if (this.queryInfo.stuStatus !== null && this.queryInfo.stuStatus !== '' &&
        this.$store.state.systemParam.stuStatus != null && this.$store.state.systemParam.stuStatus.length) {
        this.$store.state.systemParam.stuStatus.forEach(item => {
          if (Number(item.paramValue) === Number(this.queryInfo.stuStatus)) {
            stuStatusName = item.paramName
          }
        })
      }
      this.$http.exportExcelHttp.exportStudentInfo(this.queryInfo, stuStatusName + '学生名单').then()
    },
    handleSwitch(row) {
      this.$confirm(`${row.payPassFlag ? '开启后无需缴费即可登录 智慧南职APP、智慧迎新等应用。' : ''}请确认是否${row.payPassFlag ? '允许' : '禁止'}${row.stuName}(录取编号：${row.examineNo}）的免缴费登录？`, '免缴费登录').then(async () => {
        try {
          await addStudent(row)
          this.$message.success('操作成功')
        } catch (e) {
          console.error(e)
        } finally {
          await this.renderTable()
        }
      }).catch(() => {
        row.payPassFlag = row.payPassFlag ? 0 : 1
      })
    }
  }
}
</script>
